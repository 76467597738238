<template>
  <div class="average-page">
    <div class="header">
      <span class="title">场均人次</span>
      <div class="update-time">最后更新：{{ updateTime }}</div>
    </div>

    <div class="data-table">
      <div class="table-header">
        <div class="col month">月份</div>
        <div class="col amount">场均人次</div>
        <div class="col amount">本地场均人次</div>
      </div>

      <div class="table-body">
        <div 
          v-for="(item, index) in dataList" 
          :key="index"
          class="table-row"
          :class="{ 'row-even': index % 2 === 1 }"
        >
          <div class="col month">{{ item.mouth }}</div>
          <div class="col amount">{{ item.averagePerson }}人</div>
          <div class="col amount">{{ item.localAveragePerson }}人</div>
        </div>

        <div class="table-row total-row">
          <div class="col month">总平均</div>
          <div class="col amount highlight">{{ AvgSaleBySession }}人</div>
          <div class="col amount">{{ CityAvgSaleBySession }}人</div>
        </div>
      </div>
    </div>

    <div class="description">
      <van-icon name="info-o" />
      <div class="desc-text">
        页面展示1月至本月每个月平均场次人均数和本地城市平均场次人均数，总平均展示本影城场次人均数和本地城市场次人均数
      </div>
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'AveragePerson',
  data() {
    return {
      cinemaId: '',
      AvgSaleBySession: '',
      CityAvgSaleBySession: '',
      updateTime: new Date().toLocaleDateString(),
      dataList: [],
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    this.getData()
  },
  methods: {
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101605","cmdArgs":["${this.cinemaId}"]}`
      zjApi.getPs({ ps: query }).then((r) => {
        const { data, Success, Message } = r
        if (!Success) {
          Toast(Message)
        } else {
          this.AvgSaleBySession = data.AvgSaleBySession
          this.CityAvgSaleBySession = data.CityAvgSaleBySession
          this.dataList = data.monthDatas.map((item) => ({
            mouth: `${item.DateMonth}月`,
            averagePerson: item.AvgSaleBySession,
            localAveragePerson: item.CityAvgSaleBySession,
          }))
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.average-page {
  min-height: 100vh;
  background: #f5f7fa;
  padding: 16px;
}

.header {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  padding: 20px;
  border-radius: 12px;
  color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
  }

  .update-time {
    font-size: 13px;
    opacity: 0.8;
  }
}

.data-table {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

  .table-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background: #f8f9fa;
    padding: 16px 12px;
    font-weight: 500;
    color: #666;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .table-body {
    .table-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 16px 12px;
      transition: background-color 0.3s ease;
      align-items: center;
      
      &.row-even {
        background: #f8f9fa;
      }

      &:hover {
        background: #f0f7ff;
      }

      &.total-row {
        background: #e6f7ff;
        font-weight: 500;
      }
    }
  }

  .col {
    text-align: center;
    font-size: 14px;
    color: #333;

    &.month {
      color: #666;
    }

    &.amount {
      color: #1989fa;
      
      &.highlight {
        color: #f5222d;
        font-weight: 600;
      }
    }
  }
}

.description {
  margin-top: 16px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  
  .van-icon {
    font-size: 16px;
    color: #999;
    margin-top: 2px;
  }

  .desc-text {
    color: #999;
    font-size: 13px;
    line-height: 1.5;
  }
}
</style>
